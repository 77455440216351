import React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import SEO from '../components/seo';


const PreviewPage = () => {

  return (
    <div>
      <h1>Loading preview…</h1>
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage)

export const Head = ({ data }) => {
  return (
    <SEO
      title="Preview | Encord"
      description="Page preview loading screen"
    />
  );
};